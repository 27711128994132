import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./commentContainer.css";
import Comment from "../Comment";
import ajax from "../Services/fetchService";
import { useUser } from "../UserProvider";
import { useInterval } from "../util/useInterval";
import dayjs from "dayjs";

const CommentContainer = (props) => {
  const { assignmentId } = props;
  const user = useUser();
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [comments, setComments] = useState([]);

  const emptyComment = {
    id: null,
    text: "",
    assignmentId: assignmentId != null ? parseInt(assignmentId) : null,
    user: user.jwt,
    createdDate: null,
  };

  const [comment, setComment] = useState(emptyComment);

  useInterval(() => {
    updateCommentTimeDisplay();
  }, 1000 * 5);
  function updateCommentTimeDisplay() {
    const commentsCopy = [...comments];
    commentsCopy.forEach(
      (comment) => (comment.createdDate = dayjs(comment.createdDate))
    );
    formatComments(commentsCopy);
  }

  function handleEditComment(commentId) {
    const i = comments.findIndex((comment) => comment.id === commentId);
    const commentCopy = {
      id: comments[i].id,
      text: comments[i].text,
      assignmentId: assignmentId != null ? parseInt(assignmentId) : null,
      user: user.jwt,
      createdDate: comments[i].createdDate,
    };

    // Convert HTML to Draft.js content
    const contentBlock = htmlToDraft(commentCopy.text);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      setEditorState(EditorState.createWithContent(contentState));
    }
    setComment(commentCopy);
  }

  function handleDeleteComment(commentId) {
    // TODO: send DELETE request to server
    ajax(`/api/comments/${commentId}`, "delete", user.jwt).then((msg) => {
      const commentsCopy = [...comments];
      const i = commentsCopy.findIndex((comment) => comment.id === commentId);
      commentsCopy.splice(i, 1);
      formatComments(commentsCopy);
    });
  }
  function formatComments(commentsCopy) {
    commentsCopy.forEach((comment) => {
      if (typeof comment.createDate === "string") {
        comment.createDate = dayjs(comment.createDate);
      }
    });
    setComments(commentsCopy);
  }

  useEffect(() => {
    ajax(
      `/api/comments?assignmentId=${assignmentId}`,
      "get",
      user.jwt,
      null
    ).then((commentsData) => {
      formatComments(commentsData);
    });
  }, []);

  function updateComment(newEditorState) {
    setEditorState(newEditorState);
    const commentCopy = { ...comment };
    const rawContent = convertToRaw(newEditorState.getCurrentContent());

    // Replace paragraph tags with line breaks
    const html = draftToHtml(rawContent)
      .replace(/<p>/g, "")
      .replace(/<\/p>/g, "<br>")
      // Clean up multiple consecutive line breaks
      .replace(/<br><br>/g, "<br>");

    commentCopy.text = html;
    setComment(commentCopy);
  }
  function submitComment() {
    if (comment.id) {
      ajax(`/api/comments/${comment.id}`, "put", user.jwt, comment).then(
        (d) => {
          const commentsCopy = [...comments];
          const i = commentsCopy.findIndex((comment) => comment.id === d.id);
          commentsCopy[i] = d;
          formatComments(commentsCopy);
          setComment(emptyComment);
          setEditorState(EditorState.createEmpty());
        }
      );
    } else {
      ajax("/api/comments", "post", user.jwt, comment).then((d) => {
        const commentsCopy = [...comments];
        commentsCopy.push(d);
        formatComments(commentsCopy);
        setComment(emptyComment);
        setEditorState(EditorState.createEmpty());
      });
    }
  }
  return (
    <>
      <div className="mt-5">
        <h4>Comments</h4>
      </div>
      <Row>
        <Col lg="8" md="10" sm="12">
          <Editor
            editorState={editorState}
            wrapperClassName="demo-wrapper"
            editorClassName="demo-editor"
            onEditorStateChange={updateComment}
            toolbar={{
              options: [
                "inline",
                "blockType",
                "list",
                "textAlign",
                "link",
                "emoji",
              ],
              inline: {
                inDropdown: false,
                className: undefined,
                component: undefined,
                dropdownClassName: undefined,
                options: ["bold", "italic", "underline", "monospace"],
              },
              blockType: {
                inDropdown: true,
                options: ["Normal", "Code"],
                className: undefined,
                component: undefined,
                dropdownClassName: undefined,
              },
            }}
          />
        </Col>
      </Row>
      <Button onClick={() => submitComment()} className="mt-2">
        Post Comment
      </Button>
      <div className="mt-5">
        {comments.map((comment) => (
          <Comment
            key={comment.id}
            commentData={comment}
            emitDeleteComment={handleDeleteComment}
            emitEditComment={handleEditComment}
          />
        ))}
      </div>
    </>
  );
};

export default CommentContainer;
